import React, { useState, useEffect } from "react"
import Layout from "~/components/Layout"
import { queryGraphql } from "~/services/api"
import { buildBlocks } from "~/services/blockGenerator"
import { PreviewBlocks } from "~/data/fragments"
import { set } from "react-hook-form"

const Preview = () => {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState("")
  const [blocks, setBlocks] = useState([])
  // const [meta, setMeta] = useState({})
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const parsedUrl = new URL(window.location.href)
    const id =
      parsedUrl.searchParams.get("id") ||
      parsedUrl.searchParams.get("preview_id")
    const token =
      parsedUrl.searchParams.get("token") ||
      parsedUrl.searchParams.get("preview_nonce")
    const endpoint = parsedUrl.searchParams.get("endpoint")
    const qPostType = parsedUrl.searchParams.get("post_type")

    const blocksQuery = PreviewBlocks
    
    const queryObject = {
      query: `
      {
        ${qPostType ?? "page"}(id: "${id}", idType: DATABASE_ID) {
          title
          Meta {
            fieldGroupName
            metaDescription
            metaTitle
            ogDescription
            ogTitle
            twitterCardType
            twitterDescription
            twitterTitle
            twitterImage {
              uri
            }
            ogImage{
              uri
            }                            
          }
          excerpt
          featuredImage {
            node {
              mediaItemUrl
            }
          }                       
          ... on BlockEditorContentNode {
            blocks {
              ... on CoreBlock {
                reusableBlock {
                  __typename
                  ... on ReusableBlock {
                    id
                    blocks {
                      ${blocksQuery}
                      innerBlocks {
                        name
                        ${blocksQuery}
                        innerBlocks {
                          name
                          ${blocksQuery}
                          innerBlocks {
                            name
                            ${blocksQuery}                            
                          }                                     
                        }                          
                      }                                      
                    }
                  }
                } # reusableBlock
              } # CoreBlock
      
              name
              ${blocksQuery}
              innerBlocks {
                name
                ${blocksQuery}
                innerBlocks {
                  name
                  ${blocksQuery}
                }
              }
            } # blocks
          } # BlockEditorContentNode
        } # page
      }
                          `
        .replace("undefined", "")
        .trim(),
    }
    createContent(queryObject, token, endpoint)
  }, [])



  const createContent = (queryObject, token, endpoint) => {
    queryGraphql(queryObject, token, endpoint, (response) => {
      const page = response?.data?.data?.page ?? response?.data?.data?.post
      if (!page) {
        setError(true)
        setErrorMessage(
          "Unable to fetch page, try refreshing the admin panel page before clicking preview."
        )
        console.error(response.data?.errors?.map((error) => error.message))
      } else {
        const blocksData = buildBlocks(
          page?.blocks,
          {},
          {
            [new URL(window.location.href).searchParams.get("post_type")]: page,
          }
        )
        setBlocks(blocksData)
        setTitle(page?.title)
        // setMeta(page?.Meta)
        // Assuming the following line is a typo and intended to set themeOptions in state
        // setThemeOptions(response.data.data.themeOptions)
      }
      setLoading(false)
    })
  }

  if (loading === true) {
    return (
      <Layout meta={{}} title="Preview">
        <h1>Preview</h1>
        {error ? <ErrorMessage error={errorMessage} /> : <p>Loading...</p>}
      </Layout>
    )
  } else {
    return (
      <Layout meta={{}} title={`Preview: ${title}`}>
        {!error && <PreviewMessage />}
        {error && <ErrorMessage error={errorMessage} />}
        {blocks.map((block, index) => {
          return block
        })}
      </Layout>
    )
  }
}

export default Preview

// @ts-ignore
function ErrorMessage({ error }) {
  return (
    <div className="absolute top-4 right-4 z-10 my-4 mx-auto max-w-md rounded-lg border-2 border-red-600 bg-red-50 p-4 text-red-500">
      <h2 className="font-bold">Oops, an error occurred</h2>
      <p>{error}</p>
    </div>
  )
}
function PreviewMessage() {
  return (
    <div className="fixed top-8 left-8 z-10 my-4 mx-auto max-w-md rounded-lg border-2 border-blue-600 bg-blue-100 p-4 text-blue-600 transition hover:opacity-30">
      <h2 className="font-bold">This is a preview</h2>
    </div>
  )
}
